import { ref } from '@vue/composition-api'

import store from '@/store'
import router from '@/router'

import axiosClient from '@/helpers/axios'
import useAuthUser from '@/services/authentification/userService'
import useAlertNotification from '@/services/notification/useAlert'
// import useSweetAlertNotification from '@/services/notification/useSweetAlert'

export default function useAuthenticate() {
  const { notificationToast, errorToast } = useAlertNotification()

  const process = ref(false)
  const message = ref('')
  const { redirectUser, getUserAuth, user } = useAuthUser()
  // const { successSweetAlert } = useSweetAlertNotification()

  const handleLogin = async data => {
    try {
      process.value = true

      const response = await axiosClient.post('/login', data)
      if (response.data.success === true) {
        process.value = false
        await getUserAuth(response.data.data.token)
        console.log(user.value.role)
        if (user.value.email_verified_at === null) {
          if (user.value.role === 'ROLE_EXPERT' && user.value.status !== 'CREATED') {
            errorToast('Confirmation de compte', `Veuillez confirmer votre compte pour continuer. Un mail vous est envoyé à l'adresse  ${user.value.email}`)
            const TOKEN = response.data.data.token
            store.state.authStore.token = TOKEN
            store.state.authStore.authenticated = true
            const userInfo = await axiosClient.get('/user')
            store.commit('setUser', userInfo.data)
            router.push({ name: 'resend-verification-email' })
          } else {
            errorToast('Compte en cours de validation', 'Veuillez patienter pendant qu\'un administrateur s\'assure de la qualité de votre compte')
          }
        } else if (user.value.status === 'CREATED' && user.value.role === 'ROLE_EXPERT') {
          errorToast('Compte en cours de validation', 'Veuillez patienter pendant qu\'un administrateur s\'assure de la qualité de votre compte')
        } else {
          const TOKEN = response.data.data.token
          store.state.authStore.token = TOKEN
          store.state.authStore.authenticated = true
          const userInfo = await axiosClient.get('/user')
          store.commit('setUser', userInfo.data)
          await redirectUser(response.data.data)
        }
      }
    } catch (error) {
      process.value = false
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }

  const forgot = async data => {
    try {
      process.value = true
      const response = await axiosClient.post('/forgot-password', data)
      if (response.data.success === true) {
        message.value = response.data.message
        notificationToast('Modification de mot de passe', response.data.message)
        process.value = false
      }
    } catch (error) {
      process.value = false
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }

  const resendVerificationMail = async () => {
    try {
      process.value = true
      const response = await axiosClient.post('/email/verify/resend')
      if (response.status === 200) {
        message.value = response.data.message
        process.value = false
        notificationToast('Email de vérification', message.value)
      }
    } catch (error) {
      process.value = false
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  const reset = async data => {
    try {
      process.value = true

      const response = await axiosClient.post('/reset-password', data)
      if (response.data.success === true) {
        process.value = false
        notificationToast('Modification de mot de passe', response.data.message)
        router.push({ name: 'auth-login' })
      }
    } catch (error) {
      process.value = false
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  const logout = async () => {
    try {
      const response = await axiosClient.post('/logout')
      notificationToast(response.data.message)
      store.state.authStore.token = null
      store.state.authStore.authenticated = false
      store.commit('setUser', {})
      localStorage.clear()

      await router.push({ name: 'auth-login' })
    } catch (error) {
      if (error.response.status === 401) {
        notificationToast('Logged successful!')
        store.state.authStore.token = null
        store.state.authStore.authenticated = false
        store.commit('setUser', {})
        localStorage.clear()

        await router.push({ name: 'auth-login' })
      }
    }
  }

  return {
    handleLogin,
    logout,
    process,
    forgot,
    reset,
    resendVerificationMail,
    message,
  }
}
